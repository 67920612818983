import http from "./axios_init";
export default {
    /**
     * 获取支付项
     * @returns
     */
    get_terms() {
      return http.get("seller/v1/paymentTerm");
    },


    
  };
  